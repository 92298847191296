/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2017 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */
import { DestinationSearchController } from './destinationSearch-controller';
import { DestinationGridController } from './destinationsGrid-controller';
var dependencies = ['comcast.common.authentication', 'comcast.common.communication', 'comcast.common.navigation', 'comcast.destinations', 'ngMaterial', 'ngMessages', 'comcast.directives', 'ui.router'];
var defaultName = angular.module('comcast.destinations.detail', dependencies).config(['$stateProvider', function ($stateProvider) {
  $stateProvider.state('destinationList', {
    // Specify params that this state can access from the URL
    url: '/list?destinationName&multiCallLetter&marketId&destinationTypeId&destinationListId&receivedDateFrom&receivedDateTo&updatedDateFrom&updatedDateTo&sortField&sortDirection&clearedFromDate',
    parent: 'destinations',
    // Specify default values for state params
    params: {
      destinationName: null,
      callLetter: null,
      marketId: null,
      destinationTypeId: null,
      destinationListId: null,
      receivedDateFrom: null,
      receivedDateTo: null,
      updatedDateFrom: null,
      updatedDateTo: null,
      sortField: null,
      sortDirection: null,
      clearedFromDate: null
    },
    reloadOnSearch: true,
    views: {
      'destinationContent@destinations': {
        template: '<ui-view name="detailContent" class="detail-content"/>'
      },
      'destinationSearch@destinations': {
        template: '<ui-view name="detailSearch" class="detail-search" />'
      },
      'detailContent@destinationList': {
        template: require('./destinationsGrid-template.html'),
        controller: DestinationGridController,
        controllerAs: 'vm'
      },
      'detailSearch@destinationList': {
        template: require('./destinationSearch-template.html'),
        controller: DestinationSearchController,
        controllerAs: 'vm'
      }
    },
    access: {
      requiresLogin: true,
      requiredPermissions: ['User'],
      permissionCheckType: 'AtLeastOne'
    }
  }).state('destinationDetail', {
    url: '/destinations/:id',
    parent: 'destinationList',
    params: {
      gridData: null,
      pageData: null
    },
    views: {
      detailContent: {
        // This component is being loaded in destinationdetail-module (in settings)
        // In the future, we will need to move this component declaration into a core module
        template: '<destination-detail></destination-detail>'
      }
    },
    access: {
      requiresLogin: true,
      requiredPermissions: ['User'],
      permissionCheckType: 'AtLeastOne'
    }
  });
}]).name;
export { defaultName as default, dependencies };