/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2021 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */

//// CONTROLLER ////
export class DeleteDestinationListDialogController {
    //Bindable
    public providerAccountId: any;
    public listToDelete: any = null;
    public destinationLists: any = null;

    static get $inject() {
        return [
            '$state',
            '$mdDialog',
            'DestinationListResourceFactory',
            'loginService',
        ];
    }

    constructor(
        public $state: any,
        public $mdDialog: any,
        public DestinationListResourceFactory: any,
        public loginService: any
    ) {
        /* PRIVATE : DATA */
            //Declare all private variables here
            /*
             * This is the `vm` object. It is a direct reference to the controller
             * which acts as our 'view-model' in angular. It also limits our need
             * to be accessing $scope directly. */
            /*jshint validthis:true*/
            var vm = this;
            vm.providerAccountId = loginService.getSessionData().accountId;

            /* BINDABLE : DATA */
            vm.listToDelete = null;
            vm.destinationLists = null;
    }

    $onInit() {
        let vm = this;
        
        //Initialize the controller
        vm.DestinationListResourceFactory.getAll(
            { providerAccountId: vm.providerAccountId },
            {},
            function success(destinationLists:any) {
                vm.destinationLists = destinationLists.data;
            },
            function failure(err:any) {
                console.log(err);
            }
        );
    }

    /* IMPLEMENTATION : BINDABLE */
    cancel() {
        let vm = this;
        vm.$mdDialog.cancel();
    }

    deleteList() {
        let vm = this;
        vm.$mdDialog.hide(vm.listToDelete);
    }
}
