declare var angular: angular.IAngularStatic;

import { LoginService } from "../common/authentication/authentication-service";
import * as bowser from 'bowser';

export class DynamicDestinations {
    // Private
    private PAGE_SIZE: number;
    private loadedPages: any;

    // Bindable
    public SearchParams: any;
    public sessionData: any;
    public sortObj: any;
    public bowser: any;
    public numItems: number;
    public searching: any;
    public exportUrl: string;

    constructor (
        public DestinationResourceFactory: any,
        public $stateParams: any,
        public $timeout: any,
        public $scope: any,
        public loginService: LoginService,
        public destinationCapabilities: any,
        public destinationStatuses: any,
        public selectedDestinationListId: any,
    ) {
        /* PRIVATE : DATA */
        this.PAGE_SIZE = 50;
        this.numItems = 0;

        /* BINDABLE : DATA */
        this.SearchParams = $stateParams;
        this.sessionData = this.loginService.getSessionData();
        this.sortObj = {
            field: $stateParams.sortField ? $stateParams.sortField : 'date_created',
            direction: $stateParams.sortDirection ? $stateParams.sortDirection : 'ASC',
        };
        this.bowser = bowser;
        this.exportUrl = '';
        this.searching = true;

        /* EVENTS */
        //Register any event listeners
        this.$scope.$on('$stateChangeSuccess', (event:any, toState:any, toParams:any) => {
            this.SearchParams = toParams;
        });

        /* Get things rolling */
        this.reloadPages_();
    }

    getItemAtIndex = (index: any, lazy?: any) => {
        let pageNumber = Math.floor(index / this.PAGE_SIZE);
        let page = this.loadedPages[pageNumber];
        lazy = lazy === undefined ? false : lazy;

        if (page) {
            return page[index % this.PAGE_SIZE];
        } else if (page !== null) {
            if (!lazy) {
                this.fetchPage_(pageNumber);
            }
        }
    }

    getLength = () => {
        return this.numItems;
    }

    setItemAtIndex = (index: number, item: any) => {
        let pageNumber = Math.floor(index / this.PAGE_SIZE);
        let page = this.loadedPages[pageNumber];

        // Only update the screen if the spot is currently loaded
        if (page) {
            page[index % this.PAGE_SIZE] = item;
        }
    }

    fetchPage_ = (pageNumber: any) => {
        let self = this;
        self.searching = true;

        // Set the page to null so we know it is already being fetched.
        self.loadedPages[pageNumber] = null;

        let pageOffset = pageNumber * self.PAGE_SIZE;
        let sortField = self.$stateParams.sortField ? self.$stateParams.sortField : 'alias';
        let sortDirection = self.$stateParams.sortDirection ? self.$stateParams.sortDirection : 'ASC';
        let sortType = 'destination';

        self.$timeout(() => {
            // Filter out blank values, since we don't want to search by those
            for (let prop in self.SearchParams) {
                if (self.SearchParams.hasOwnProperty(prop)) {
                    self.SearchParams[prop] =
                    self.SearchParams[prop] === '' || self.SearchParams[prop] === null
                            ? undefined
                            : self.SearchParams[prop];
                }
            }

            self.SearchParams.offset = pageOffset;
            self.SearchParams.limit = self.PAGE_SIZE;
            self.SearchParams.sortField = sortField;
            self.SearchParams.sortDirection = sortDirection;
            self.SearchParams.sortType = sortType;

            let sendParams = angular.copy(self.SearchParams);
            self.selectedDestinationListId = sendParams.destinationListId;

            if (self.selectedDestinationListId) {
                sendParams.destinationListType = 'account';
            }

            if (sendParams.callLetter !== undefined) {
                // if they only entered one, then do a "LIKE" search via the alias param.  If multiple, then format it correctly and send via multiCallLetter param.
                let parsedInput = sendParams.callLetter.trim().split(/\n+|,+|;+/);
                if (parsedInput instanceof Array && parsedInput.length > 1) {
                    let validArray = [];
                    //remove any that ended up empty due to extra characters, and trim
                    for (let i = 0; i < parsedInput.length; i++) {
                        if (parsedInput[i].trim().length > 0) {
                            validArray.push(parsedInput[i].trim());
                        }
                    }
                    sendParams.multiCallLetter = validArray.join();
                } else {
                    sendParams.alias = sendParams.callLetter;
                }
            }

            self.DestinationResourceFactory.getAll(
                sendParams,
                function success(destinations: any) {
                    self.loadedPages[pageNumber] = destinations.data.rows;
                    self.numItems = destinations.data.count;
                    self.exportUrl = destinations.data.exportUrl;
                    self.loadStatuses(destinations.data.rows);
                    self.loadCapabilities(destinations.data.rows);
                    self.searching = false;
                },
                function failure() {
                    self.loadedPages[pageNumber] = [];
                }
            );
        });
    }

    reloadPages_ = () => {
        /**
         * @type {!Object<?Array>} Data pages, keyed by page number (0-index).
         */
        this.loadedPages = {};

        /** @type {number} Total number of items. */
        this.numItems = 0;

        /** @const {number} Number of items to fetch per request. */
        this.PAGE_SIZE = 25;

        this.getItemAtIndex(0);
    }

    loadStatuses(destinations:any) {
        let vm = this;
        angular.forEach(destinations, function (dest, key) {
            vm.DestinationResourceFactory.getStatus(
                {
                    id: dest.id,
                    distributionType: dest.distributionType
                        ? dest.distributionType
                        : dest.distribution_type,
                    receiverId: dest.ReceiverAccount.id,
                },
                function (status:any) {
                    vm.$timeout(function () {
                        vm.destinationStatuses[dest.id] =
                            status.data == null || status.data == undefined
                                ? 'false'
                                : status.data.toString();
                    });
                },
                function failure() {}
            );
        });
    }

    loadCapabilities(destinations:any) {
        let vm = this;

        angular.forEach(destinations, function (dest, key) {
            vm.DestinationResourceFactory.getCapabilities(
                { id: dest.id },
                function (capabilities:any) {
                    vm.$timeout(function () {
                        vm.destinationCapabilities[dest.id] =
                            capabilities && capabilities.data ? capabilities.data : {};
                    });
                },
                function failure() {
                    vm.destinationCapabilities[dest.id] = {};
                }
            );
        });
    }
}
    