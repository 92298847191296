/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2021 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */

declare var angular: angular.IAngularStatic;
import * as moment from 'moment-timezone';

import { UpdatedDatePanelController } from '../orders/updatedDatePanel-controller';

//// CONTROLLER ////
export class DestinationSearchController {
    // Private values
    private sessionData: any;
    
    //Bindable
    public searchParams: any = {};
    public markets: any = [];
    public destinationTypes: any = [];
    public destinationLists: any = [];
    public statuses: any = [];
    public orderTypes: any = [];
    public updatedDateSearchText: string = '';
    public receivedDateSearchText: string = '';
    public minDate: Date = new Date();

    static get $inject() {
        return [
            '$location',
            '$q',
            '$state',
            '$stateParams',
            '$scope',
            'loginService',
            'MarketResourceFactory',
            'DestinationTypeResourceFactory',
            'DestinationListResourceFactory',
            'EnumService',
            '$mdPanel',
            '$timeout',
            '$filter',
        ];
    }

    constructor(
        public $location: any,
        public $q: any,
        public $state: any,
        public $stateParams: any,
        public $scope: any,
        public loginService: any,
        public MarketResourceFactory: any,
        public DestinationTypeResourceFactory: any,
        public DestinationListResourceFactory: any,
        public EnumService: any,
        public $mdPanel: any,
        public $timeout: any,
        public $filter: any
    ) {
        /*
            * This is the `vm` object. It is a direct reference to the controller
            * which acts as our 'view-model' in angular. It also limits our need
            * to be accessing $scope directly. */
        /*jshint validthis:true*/
        var vm = this;

        /* PRIVATE : DATA */
        //Declare all private variables here
        vm.sessionData = loginService.getSessionData();

        /* BINDABLE : DATA */
    }

    $onInit() {
        let vm = this;
        
        vm.searchParams = vm.$stateParams;

        vm.loadMarkets();
        vm.loadDestinationTypes();
        vm.loadDestinationLists();

        vm._setElectronicDateSearchText();
        vm._setUpdatedDateSearchText();
    }

    /* IMPLEMENTATION : BINDABLE */
    search() {
        let vm = this;

        if (!vm.searchParams.updatedDateFrom) {
            vm.searchParams.clearedFromDate = true;
        } else {
            vm.searchParams.clearedFromDate = null;
        }

        vm.checkUpdatedDate();
        vm.checkReceivedDate();
        vm.$state.go('destinationList', vm.searchParams, {
            notify: true,
            inherit: false,
            reload: true,
        });
    }

    clear() {
        let vm = this;
        
        vm.searchParams = {};
        vm.receivedDateSearchText = '';
        vm.updatedDateSearchText = '';
    }

    loadMarkets() {
        let vm = this;
        
        vm.MarketResourceFactory.getAll(
            {},
            function (markets: any) {
                vm.markets = markets.data;
            },
            function () {
                vm.markets = [];
            }
        );
    }

    loadDestinationTypes() {
        let vm = this;
        
        vm.DestinationTypeResourceFactory.getAll(
            {},
            function (types: any) {
                vm.destinationTypes = types.data;
            },
            function () {
                vm.destinationTypes = [];
            }
        );
    }

    loadDestinationLists() {
        let vm = this;
        
        var destinationListSearch = vm.$q.defer();

        vm.DestinationListResourceFactory.getAll(
            { providerAccountId: vm.sessionData.accountId },
            function (lists: any) {
                vm.destinationLists = lists.data;
                destinationListSearch.resolve(lists.data);
            },
            function () {
                vm.destinationLists = [];
                destinationListSearch.resolve([]);
            }
        );

        return destinationListSearch.promise;
    }

    showUpdatedDate() {
        let vm = this;
        
        var position = vm.$mdPanel
            .newPanelPosition()
            .relativeTo('.updated-date-input')
            .addPanelPosition(vm.$mdPanel.xPosition.OFFSET_END, vm.$mdPanel.yPosition.CENTER);

        var updatedDatePanel = {
            attachTo: angular.element(document.body),
            controller: UpdatedDatePanelController,
            controllerAs: 'ctrl',
            disableParentScroll: true,
            template: require('../spots/updatedDatePanel-template.html'),
            scope: vm.$scope,
            preserveScope: true,
            panelClass: 'search-popover-panel',
            position,
            clickOutsideToClose: true,
            escapeToClose: true,
            focusOnOpen: true,
            zIndex: 2,
            onRemoving() {
                vm._setUpdatedDateSearchText();
            },
            locals: {
                minDate: vm.minDate,
            },
        };

        vm.$mdPanel.open(updatedDatePanel);
    }

    showReceivedDate() {
        let vm = this;
        
        var position = vm.$mdPanel
            .newPanelPosition()
            .relativeTo('.received-date-input')
            .addPanelPosition(vm.$mdPanel.xPosition.OFFSET_END, vm.$mdPanel.yPosition.CENTER);

        var receivedDatePanel = {
            attachTo: angular.element(document.body),
            controller: ReceivedDatePanelController,
            controllerAs: 'ctrl',
            disableParentScroll: true,
            template: require('../orders/receivedDatePanel-template.html'),
            scope: vm.$scope,
            preserveScope: true,
            panelClass: 'search-popover-panel',
            position,
            clickOutsideToClose: true,
            escapeToClose: true,
            focusOnOpen: true,
            zIndex: 2,
            onRemoving() {
                vm._setElectronicDateSearchText();
            },
        };

        vm.$mdPanel.open(receivedDatePanel);
    }

    checkUpdatedDate() {
        let vm = this;
        
        if (!vm.updatedDateSearchText) {
            vm.searchParams.updatedDateFrom = null;
            vm.searchParams.updatedDateTo = null;
            vm.searchParams.clearedFromDate = true;
        } else if (!vm.searchParams.updatedDateFrom) {
            vm.searchParams.updatedDateFrom = null;
            vm.searchParams.clearedFromDate = true;
        } else if (!vm.searchParams.updatedDateTo) {
            vm.searchParams.updatedDateTo = null;
        } else {
            vm.searchParams.clearedFromDate = null;
        }
    }

    checkReceivedDate() {
        let vm = this;
        
        if (!vm.receivedDateSearchText) {
            vm.searchParams.receivedDateFrom = null;
            vm.searchParams.receivedDateTo = null;
        }
    }

    clearCriteria() {
        let vm = this;
        
        var listSelection = vm.searchParams.destinationListId;

        // vm.receivedDateSearchText = '';
        // vm.updatedDateSearchText = '';
        vm.searchParams = {
            destinationListId: listSelection,
        };

        vm._setElectronicDateSearchText();
        vm._setUpdatedDateSearchText();
    }

    clearList() {
        let vm = this;
        
        delete vm.searchParams.destinationListId;
    }

    /* IMPLEMENTATION : PRIVATE */

    _setUpdatedDateSearchText() {
        let vm = this;
        
        var returnText = '';
        if (!vm.searchParams.updatedDateFrom && !!vm.searchParams.updatedDateTo) {
            returnText =
                'before ' + moment(vm.searchParams.updatedDateTo).format('MM/DD/YYYY');
            vm.searchParams.dateUpdated =
                'lte:' +
                moment(new Date(vm.searchParams.updatedDateTo))
                    .hour(23)
                    .minute(59)
                    .second(59)
                    .format('MM-DD-YYYY h:mm:ss a');
        } else if (
            !!vm.searchParams.updatedDateFrom &&
            !vm.searchParams.updatedDateTo
        ) {
            returnText =
                'after ' + moment(vm.searchParams.updatedDateFrom).format('MM/DD/YYYY');
            vm.searchParams.dateUpdated =
                'gte:' +
                moment(new Date(vm.searchParams.updatedDateFrom))
                    .hour(0)
                    .minute(0)
                    .second(0)
                    .format('MM-DD-YYYY h:mm:ss a');
            // If it has both dates
        } else if (
            !!vm.searchParams.updatedDateFrom &&
            !!vm.searchParams.updatedDateTo
        ) {
            returnText =
                moment(vm.searchParams.updatedDateFrom).format('MM/DD/YYYY') +
                ' to ' +
                moment(vm.searchParams.updatedDateTo).format('MM/DD/YYYY');
            vm.searchParams.dateUpdated =
                'bt:[' +
                moment(new Date(vm.searchParams.updatedDateFrom))
                    .hour(0)
                    .minute(0)
                    .second(0)
                    .format('MM-DD-YYYY h:mm:ss a') +
                ',' +
                moment(new Date(vm.searchParams.updatedDateTo))
                    .hour(23)
                    .minute(59)
                    .second(59)
                    .format('MM-DD-YYYY h:mm:ss a') +
                ']';
        }
        vm.updatedDateSearchText = returnText;

        if (returnText !== '') {
            vm.clearList();
        }
    }

    _setElectronicDateSearchText() {
        let vm = this;
        
        var returnText = '';
        if (!vm.searchParams.receivedDateFrom && !!vm.searchParams.receivedDateTo) {
            returnText =
                'before ' + moment(vm.searchParams.receivedDateTo).format('MM/DD/YYYY');
            vm.searchParams.dateElectronic =
                'lte:' +
                moment(new Date(vm.searchParams.receivedDateTo))
                    .hour(23)
                    .minute(59)
                    .second(59)
                    .format('MM-DD-YYYY h:mm:ss a');
        } else if (
            !!vm.searchParams.receivedDateFrom &&
            !vm.searchParams.receivedDateTo
        ) {
            returnText =
                'after ' +
                moment(vm.searchParams.receivedDateFrom).format('MM/DD/YYYY');
            vm.searchParams.dateElectronic =
                'gte:' +
                moment(new Date(vm.searchParams.receivedDateFrom))
                    .hour(0)
                    .minute(0)
                    .second(0)
                    .format('MM-DD-YYYY h:mm:ss a');
            // If it has both dates
        } else if (
            !!vm.searchParams.receivedDateFrom &&
            !!vm.searchParams.receivedDateTo
        ) {
            returnText =
                moment(vm.searchParams.receivedDateFrom).format('MM/DD/YYYY') +
                ' to ' +
                moment(vm.searchParams.receivedDateTo).format('MM/DD/YYYY');
            vm.searchParams.dateElectronic =
                'bt:[' +
                moment(new Date(vm.searchParams.receivedDateFrom))
                    .hour(0)
                    .minute(0)
                    .second(0)
                    .format('MM-DD-YYYY h:mm:ss a') +
                ',' +
                moment(new Date(vm.searchParams.receivedDateTo))
                    .hour(23)
                    .minute(59)
                    .second(59)
                    .format('MM-DD-YYYY h:mm:ss a') +
                ']';
        }
        vm.receivedDateSearchText = returnText;

        if (returnText !== '') {
            vm.clearList();
        }
    }
}

//// CONTROLLER ////
export class ReceivedDatePanelController {
    //Bindable

    static get $inject() {
        return [];
    }

    constructor() {
        /* BINDABLE: DATA */
    }

    $onInit() {}
}
