/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2021 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */

declare var angular: angular.IAngularStatic;

import * as bowser from 'bowser';
import * as Flow from '@flowjs/flow.js';

import { AuthConstant } from '../../constants/auth.constant';
import { DeleteDestinationListDialogController } from './deleteDestinationListDialog-controller';
import { AddSelectedDestinationsDialogController } from './addSelectedDestinationsDialog-controller';
import { DynamicDestinations } from './destinations-dynamicItems';
import { NewDestinationListDialogController } from './newDestinationListDialog-controller';

//// CONTROLLER ////
export class DestinationGridController {
    // Private values
    private authConstants: any;

    // Bindable
    public dynamicItems: any;
    public destinationCapabilities: any = [];
    public destinationStatuses: any = [];
    public selectedDestinationListId: any;
    public sortObj: any;
    public selectedDestinations: any = [];
    public lastSelection: any = -1;
    public onlyArchivesSelected: boolean = true;
    public viewSelections: any;
    public viewUrls: any;
    public deliveryPriorities: any = [];
    public onlyDraftsSelected: boolean = true;
    public destinationRemovedCount: any = 0;
    public destinationNotRemovedCount: any = 0;
    public destinationAddedCount: any = 0;
    public destinationNotAddedCount: any = 0;
    public fileToUpload: any;
    public importing: boolean = false;
    public bowser: any = bowser;

    static get $inject() {
        return [
            '$cookies',
            '$mdDialog',
            '$q',
            '$scope',
            '$state',
            '$stateParams',
            '$timeout',
            '$window',
            'AssetResourceFactory',
            'EndPointService',
            'loginService',
            'socket',
            'StatusService',
            'EnumService',
            'NotificationService',
            'DestinationResourceFactory',
            'DestinationListResourceFactory',
        ];
    }

    constructor(
        public $cookies: any,
        public $mdDialog: any,
        public $q: any,
        public $scope: any,
        public $state: any,
        public $stateParams: any,
        public $timeout: any,
        public $window: any,
        public AssetResourceFactory: any,
        public EndPointService: any,
        public loginService: any,
        public socket: any,
        public statusService: any,
        public EnumService: any,
        public NotificationService: any,
        public DestinationResourceFactory: any,
        public DestinationListResourceFactory: any
    ) {
        /*
         * This is the `vm` object. It is a direct reference to the controller
         * which acts as our 'view-model' in angular. It also limits our need
         * to be accessing $scope directly. */
        /*jshint validthis:true*/
        let vm = this;

        /* PRIVATE : DATA */
        //Declare all private variables here
        vm.authConstants = new AuthConstant();

        vm.selectedDestinationListId = $stateParams.destinationListId;

        /* BINDABLE : DATA */
        vm.dynamicItems = new DynamicDestinations(
            vm.DestinationResourceFactory,
            vm.$stateParams,
            vm.$timeout,
            vm.$scope,
            vm.loginService,
            vm.destinationCapabilities,
            vm.destinationStatuses,
            vm.selectedDestinationListId,
        );
        
        vm.sortObj = {
            field: $stateParams.sortField ? $stateParams.sortField : 'date_created',
            direction: $stateParams.sortDirection ? $stateParams.sortDirection : 'ASC',
        };
        vm.viewSelections = {
            deliveryConfirmationUrl: false,
            costEstimateReportUrl: false,
            receiptConfirmationReportUrl: false,
        };
        vm.viewUrls = {
            deliveryConfirmationUrl: false,
            costEstimateReportUrl: false,
            receiptConfirmationReportUrl: false,
        };
        
        // Do we need this? - DynamicDestinations already has it
        // vm.$scope.$on('$stateChangeSuccess', function (event:any, toState:any, toParams:any) {
        //     vm.dynamicItems.SearchParams = toParams;
        // });
    }

    $onInit() {
        let vm = this;
        
        angular.element(document.querySelector('.sidebar') as HTMLElement).removeClass('hide');
        vm.$scope.home.showTgl = true;
    }

    /* IMPLEMENTATION : BINDABLE */
    sortDestinations(field:any) {
        let vm = this;

        if (vm.sortObj.field === field) {
            vm.sortObj.direction = vm.sortObj.direction === 'ASC' ? 'DESC' : 'ASC';
        } else {
            vm.sortObj.field = field;
            vm.sortObj.direction = 'ASC';
        }
        vm.$state.go(
            'destinationList',
            {
                sortField: vm.sortObj.field,
                sortDirection: vm.sortObj.direction,
            },
            { inherit: true }
        );
    }

    selectDestination(destination:any, index:any, event:any) {
        let vm = this;

        if (!event.ctrlKey && !event.metaKey && !event.shiftKey) {
            vm.selectedDestinations = [];
        }

        if (event.shiftKey && vm.lastSelection > -1) {
            // Do multiselect
            var toIndex = Math.max(index, vm.lastSelection);
            var fromIndex = Math.min(index, vm.lastSelection);

            for (let i = fromIndex; i <= toIndex; i++) {
                if (vm.selectedDestinations.indexOf(i) < 0) {
                    vm.selectedDestinations.push(i);
                }
            }
        } else if (vm.selectedDestinations.indexOf(index) < 0) {
            vm.selectedDestinations.push(index);
        } else {
            vm.selectedDestinations.splice(vm.selectedDestinations.indexOf(index), 1);
        }

        vm.onlyArchivesSelected = true;
        vm.onlyDraftsSelected = true;
        for (var destIndex = 0; destIndex < vm.selectedDestinations.length; destIndex++) {
            if (
                vm.dynamicItems.getItemAtIndex(vm.selectedDestinations[destIndex])
                    .archived !== true
            ) {
                vm.onlyArchivesSelected = false;
            }
            if (
                vm.dynamicItems.getItemAtIndex(vm.selectedDestinations[destIndex])
                    .deliveryStatus !== 'DRAFT'
            ) {
                vm.onlyDraftsSelected = false;
            }
        }

        vm.lastSelection = index;
    }

    openDetail(id:any) {
        let vm = this;

        angular.element(document.querySelector('.sidebar') as HTMLElement).addClass('hide');
        vm.$state.go('destinationDetail', { id }).then(function () {});
    }

    isSelected(rolesToValidate:any) {
        let vm = this;

        return (
            vm.selectedDestinations.length < 1 ||
            !vm.loginService.hasPermission(rolesToValidate.split(','))
        );
    }

    newList() {
        let vm = this;

        var dialog = vm.$mdDialog.show({
            controller: NewDestinationListDialogController,
            controllerAs: 'vm',
            template: require('../destinations/newDestinationListDialog-template.html'),
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            fullscreen: true,
        });
        dialog.then(
            function success(name:any) {
                vm._createDestinationList(name).then(function success() {
                    vm.$state.go('destinationList', vm.dynamicItems.SearchParams, {
                        notify: true,
                        inherit: false,
                        reload: true,
                    });
                });
            },
            function failure() {
                //user canceled the dialog
            }
        );
    }

    deleteList() {
        let vm = this;

        var dialog = vm.$mdDialog.show({
            controller: DeleteDestinationListDialogController,
            controllerAs: 'vm',
            template: require('../destinations/deleteDestinationListDialog-template.html'),
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            fullscreen: true,
        });
        dialog.then(
            function success(list:any) {
                var confirmOrNot;

                var confirm = vm.$mdDialog
                    .confirm()
                    .title('Delete Destination List')
                    .textContent('Are you sure you want to delete this list?')
                    .ariaLabel('Are you sure you want to delete this list?')
                    .ok('Delete')
                    .cancel('Cancel');
                confirmOrNot = vm.$mdDialog.show(confirm);

                confirmOrNot.then(
                    function () {
                        vm.DestinationListResourceFactory.delete(
                            { id: list.id },
                            {},
                            function success() {
                                vm.NotificationService.showNotificationToast(
                                    'Destination List ' +
                                        list.name +
                                        ' successfully deleted'
                                );
                                vm.$state.go('destinationList', vm.dynamicItems.SearchParams, {
                                    notify: true,
                                    inherit: false,
                                    reload: true,
                                });
                            },
                            function failure(err:any) {
                                //something went wrong with the request
                                vm.NotificationService.showNotificationToast(
                                    'Destination List deletion failed',
                                    err
                                );
                            }
                        );
                    },
                    function () {
                        // User cancelled the delete function so don't do anything
                    }
                );
            },
            function failure() {
                //user canceled the dialog
            }
        );
    }

    addSelectedToList() {
        let vm = this;

        vm._addOrImportDialog(false);
    }

    importList() {
        let vm = this;
        
        vm._addOrImportDialog(true);
    }

    disableRemoveDestinationFromListButton() {
        let vm = this;
        
        if (
            vm.selectedDestinations.length > 0 &&
            (vm.selectedDestinationListId != undefined ||
                vm.selectedDestinationListId != null)
        ) {
            return false;
        } else {
            return true;
        }
    }

    removeDestinationFromList() {
        let vm = this;
        let confirmOrNot;

        let confirm = vm.$mdDialog
            .confirm()
            .title('Remove Destination From List')
            .textContent('Are you sure you want to remove destination(s) From list?')
            .ariaLabel('Are you sure you want to remove destination(s) From list?')
            .ok('REMOVE')
            .cancel('Cancel');
        confirmOrNot = vm.$mdDialog.show(confirm);

        confirmOrNot.then(
            function () {
                // User confirmed the remove destination function
                vm.$q.all(
                    vm.selectedDestinations.map(function (cur:any) {
                        return vm._removeDestinationFromList(
                            vm.selectedDestinationListId,
                            vm.dynamicItems.getItemAtIndex(cur)
                        );
                    })
                ).then(
                    function success() {
                        var msg =
                            vm.destinationRemovedCount > 0
                                ? vm.destinationRemovedCount +
                                  ' destination(s) were removed.  '
                                : '';
                        msg +=
                            vm.destinationNotRemovedCount > 0
                                ? vm.destinationNotRemovedCount +
                                  ' destination(s) were UNABLE to be removed.'
                                : '';
                        if (msg) {
                            vm.NotificationService.showNotificationToast(msg);
                        }
                        vm.destinationRemovedCount = 0;
                        vm.destinationNotRemovedCount = 0;
                        vm.$state.reload();
                    },
                    function failure(err:any) {
                        //spot failed to be deleted
                        vm.NotificationService.showNotificationToast(
                            'Error removing destinations',
                            err
                        );
                    }
                );
            },
            function () {
                // User cancelled the remove destination function
            }
        );
    }

    exportToCSV() {
        let vm = this;

        //make CSV Export call
        vm.$window.open(vm.dynamicItems.exportUrl + '&authorization=' + vm.loginService.getJwt());
    }

    /* IMPLEMENTATION : PRIVATE */
    // All private methods should start with '_' in order to distinguish them
    _createDestinationList(name:any) {
        let vm = this;
        var createPromise = vm.$q.defer();

        vm.DestinationListResourceFactory.create(
            { name },
            {},
            function (success:any) {
                vm.NotificationService.showNotificationToast(
                    'Destination List ' + name + ' successfully created'
                );
                createPromise.resolve(success);
            },
            function (failure:any) {
                //individual removal failure
                vm.NotificationService.showNotificationToast(
                    'Destination List creation failed',
                    failure
                );
                createPromise.resolve(failure);
            }
        );

        return createPromise.promise;
    }

    _addNewDestination(list:any, destination:any) {
        let vm = this;
        let addPromise = vm.$q.defer();

        vm.DestinationListResourceFactory.addDestination(
            {
                destinationGroupId: list.id,
                destinationId: destination.id,
                sequence: destination.sequence,
            },
            function () {
                //individual removal succeeded
                vm.destinationAddedCount++;
                addPromise.resolve('true');
            },
            function () {
                //individual removal failure
                vm.destinationNotAddedCount++;
                addPromise.resolve('true');
            }
        );

        return addPromise.promise;
    }

    _addOrImportDialog(importing:any) {
        let vm = this;

        let dialog = vm.$mdDialog.show({
            controller: AddSelectedDestinationsDialogController,
            controllerAs: 'vm',
            template: require('../destinations/addSelectedDestinationsDialog-template.html'),
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            fullscreen: true,
            locals: {
                importing,
            },
        });
        dialog.then(
            function success(data:any) {
                // If we're importing, go no further without an import file
                if (importing && !data.importFile) {
                    vm.NotificationService.showNotificationToast(
                        'Error adding destinations',
                        'No import file was selected'
                    );
                    return true;
                }

                if (data.isNew) {
                    vm._createDestinationList(data.list).then(
                        function success(createdList:any) {
                            // Hold the newly created list
                            let newListData = createdList.data;

                            // If we are importing, we need to send the file to the server
                            if (importing) {
                                // Send the import file to the server
                                vm._importDestinationsFile(newListData.id, data.importFile);

                                // If not importing, add the selected destinations
                            } else {
                                // Add each selected Destination to the DestinationList
                                vm.$q.all(
                                    vm.selectedDestinations.map(function (cur:any, index:any) {
                                        let curDest = vm.dynamicItems.getItemAtIndex(cur);
                                        curDest.sequence = index + 1;
                                        return vm._addNewDestination(newListData, curDest);
                                    })
                                ).then(
                                    function success() {
                                        // Create a notification with the results
                                        let msg =
                                            vm.destinationAddedCount > 0
                                                ? vm.destinationAddedCount +
                                                  ' destination(s) were added.  '
                                                : '';
                                        msg +=
                                            vm.destinationNotAddedCount > 0
                                                ? vm.destinationNotAddedCount +
                                                  ' destination(s) were UNABLE to be added.'
                                                : '';

                                        // If anything happened, show the notification
                                        if (msg) {
                                            vm.NotificationService.showNotificationToast(msg);
                                        }

                                        // Reset the counts after displaying them to the user for the next import or what-have-you
                                        vm.destinationAddedCount = 0;
                                        vm.destinationNotAddedCount = 0;

                                        // Refresh the view so that the user can see their shiny new list
                                        vm.$state.go('destinationList', vm.dynamicItems.SearchParams, {
                                            notify: true,
                                            inherit: false,
                                            reload: true,
                                        });
                                    },
                                    function failure(err:any) {
                                        //Destination failed to be added
                                        vm.NotificationService.showNotificationToast(
                                            'Error adding destinations',
                                            err
                                        );
                                    }
                                );
                            }
                        },
                        function failure() {}
                    );
                } else if (importing) {
                    let continueOrNot;

                    if (data.list.includedDestinations > 0) {
                        let confirm = vm.$mdDialog
                            .confirm()
                            .title('Warning')
                            .textContent(
                                'Importing Destinations to an existing Destination List will remove all previous destinations. Do you want to continue?'
                            )
                            .ariaLabel(
                                'Importing Destinations to an existing Destination List will remove all previous destinations. Do you want to continue?'
                            )
                            .ok('Yes')
                            .cancel('No');
                        continueOrNot = vm.$mdDialog.show(confirm);
                    } else {
                        continueOrNot = vm.$q.when(true);
                    }

                    continueOrNot.then(
                        function yes() {
                            vm._importDestinationsFile(data.list.id, data.importFile);
                        },
                        function no() {
                            // User cancelled the operation
                        }
                    );
                } else {
                    // Add each selected Destination to the DestinationList
                    vm.$q.all(
                        vm.selectedDestinations.map(function (cur:any, index:any) {
                            let curDest = vm.dynamicItems.getItemAtIndex(cur);
                            curDest.sequence = index + 1;
                            return vm._addNewDestination(data.list, curDest);
                        })
                    ).then(
                        function success() {
                            // Create a notification with the results
                            let msg =
                                vm.destinationAddedCount > 0
                                    ? vm.destinationAddedCount +
                                      ' destination(s) were added.  '
                                    : '';
                            msg +=
                                vm.destinationNotAddedCount > 0
                                    ? vm.destinationNotAddedCount +
                                      ' destination(s) were UNABLE to be added.'
                                    : '';

                            // If anything happened, show the notification
                            if (msg) {
                                vm.NotificationService.showNotificationToast(msg);
                            }

                            // Reset the counts after displaying them to the user for the next import or what-have-you
                            vm.destinationAddedCount = 0;
                            vm.destinationNotAddedCount = 0;
                        },
                        function failure(err:any) {
                            //Destination failed to be added
                            vm.NotificationService.showNotificationToast(
                                'Error adding destinations',
                                err
                            );
                        }
                    );
                }
            },
            function failure() {
                //user canceled the dialog
            }
        );
    }

    _importDestinationsFile(listId:any, importFile:any) {
        let vm = this;

        // Create a new Flow object that will be used to upload the file
        vm.fileToUpload = new Flow({
            target: vm.EndPointService.destinationListImportEndPoint + '?listId=' + listId,
            headers() {
                let accountHash = JSON.parse(
                    vm.$window.localStorage[vm.authConstants.session.ACCOUNT_HASH]
                );
                let windowSessionData = JSON.parse(
                    vm.$window.sessionStorage[vm.authConstants.session.SESSION_DATA]
                );

                return {
                    Authorization: 'Bearer ' + accountHash[windowSessionData.accountId],
                };
            },
            uploadMethod: 'POST',
            withCredentials: true,
            allowDuplicateUploads: true,
            // 100Mb chunks, should force everything to go at once, since it's limited to that max size
            // It's important to send this all at once since we're going straight to RE
            chunkSize: 100 * 1024 * 1024,
            query: {
                listId,
            },
            testChunks: false, // This allows flow.js to make test calls to see which chunks have already been uploaded. So it can resume where it left off.
        });

        // Add the spot file to the uploader
        vm.fileToUpload.addFile(importFile);

        // Handle upload events
        vm.fileToUpload.off();
        // Once the upload completes, submit the metadata
        vm.fileToUpload.on('fileError', function (file:any, message:any) {
            vm.importing = false;
            vm.NotificationService.showNotificationToast('Error adding destinations', message);
        });

        vm.fileToUpload.on('fileSuccess', function (file:any, destinationsFound:any) {
            vm.importing = false;
            let parsed = JSON.parse(destinationsFound);
            let successes = parsed.filter((cur:any) => cur.status === 'fulfilled').length;
            let failures = parsed.filter((cur:any) => cur.status === 'rejected').length;
            let invalids = parsed.filter((cur:any) => cur.status === 'invalid').length;

            // Build the message to show the user
            let userMessage = '';

            // Create a notification with the results
            if (successes > 0) {
                userMessage += successes + ' ';
                if (successes === 1) {
                    userMessage += 'destination was added.';
                } else {
                    userMessage += 'destinations were added.';
                }
            }

            if (successes > 0 && failures > 0) {
                userMessage += '  ';
            }

            if (failures > 0) {
                userMessage += failures + ' ';
                if (failures === 1) {
                    userMessage += 'destination was UNABLE to be added.';
                } else {
                    userMessage += 'destinations were UNABLE to be added.';
                }
            }

            // If anything happened, show the notification
            if (userMessage && invalids === 0) {
                vm.NotificationService.showNotificationToast(userMessage);
            } else if (userMessage && invalids > 0) {
                // If there were invalid rows, give the user a little more information
                vm.NotificationService.showNotificationToast(
                    userMessage,
                    '<p><b>' +
                        invalids +
                        ' ' +
                        (invalids === 1 ? 'entry' : 'entries') +
                        '</b> in your import file appeared to be incomplete.</p>' +
                        '<p>This usually indicates that there was an unexpected new line in your file or that the file was malformed.</p>' +
                        '<p>Where possible, destinations were still added to the destination list.</p>'
                );
            }
        });

        vm.importing = true;

        // Begin the upload
        vm.fileToUpload.upload();
    }

    _removeDestinationFromList(destinationGroupId:any, destination:any) {
        let vm = this;
        var removePromise = vm.$q.defer();

        vm.DestinationListResourceFactory.removeDestinationFromList(
            {
                destinationGroupId,
                destinationId: destination.id,
            },
            function () {
                //individual removal succeeded
                vm.destinationRemovedCount++;
                removePromise.resolve('true');
            },
            function () {
                //individual removal failure
                vm.destinationNotRemovedCount++;
                removePromise.resolve('true');
            }
        );

        return removePromise.promise;
    }
}
